.SortableList {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0;
    list-style: none;
    margin: 0px;
  }
  
.SortableItem {

  padding: 0px 5px !important;  
  height: 75px;

}