.accountLogo {
    width: 40px;
    height: 40px;
    margin: 5px;
    margin-left: 0px;
    margin-right: 5px;

}

.songPickerAppContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.songPickerContent {
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
} 
.songPickerSelectorArea {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.songPickerSelectorArea h3 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.songPickerSelectorArea .noItemsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.songPickerSelectorSearch {
    flex: 1;
}

.songPickerSelectorButton {
    position: fixed !important;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 80px, 80px, 80px black;
}

.songPickerSearchDrawer {
    border-radius: 5px 5px 0px 0px;

}

.songPickerRequest {
    height: 75px;    
    display: flex;
    flex-direction: row;
    padding: 0px 0px 5px 0px;
    justify-content: center;
    align-items: center;
}

.songPickerRequestImage {
    width: 60px;
    height: 60px;
    border-radius: 3px;
}

.songPickerRequestTextContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    flex: 1;
    overflow: hidden;
    text-align: left;
    font-family: "PT Sans Caption", sans-serif !important;
    width: calc(100vw - 230px);
}

.songPickerRequestName {
    font-size: 14px;
    font-weight: bold;
    color: #212121;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: calc(100vw - 230px);

}

.songPickerRequestArtists {
    font-size: 12px;
    color: #212121;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100vw - 230px);
}

.songPickerRequestArtists span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    flex: 1;

}

.songPickerRequestActionArea {
    width: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.queueViewActionArea {
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.queuedSongs {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.viewsongModalHeader {
    position: absolute;
    top: 50px; 
    left: 20px;    
    width: calc(100vw - 40px);
    height: calc(100vh - 100px);

}

.songRequestedByRow {
    display: flex;
    align-items: center;
}

