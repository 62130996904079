.songSearchDiv {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    height: calc(350px);
}

.searchResults {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
}

/* hide search results scroll bar */
.searchResults::-webkit-scrollbar {
    display: none;
}

.searchResult {
    height: 60px;    
    display: flex;
    flex-direction: row;
    padding: 0px 10px 0px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
}

.searchResultImage {
    width: 40px;
    height: 40px;
    border-radius: 3px;
}

.searchResultTextContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    flex: 1;
    overflow: hidden;
    text-align: left;
    font-family: "PT Sans Caption", sans-serif !important;
}

.searchResultName {
    font-size: 12px;
    font-weight: bold;
    color: #212121;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}

.searchResultArtists {
    font-size: 10px;
    color: #212121;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchResultActionArea {
    
}
