@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&display=swap');


body {
  display: block;
  font-family: "PT Sans Caption", sans-serif !important;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  position: fixed;
}

.AppLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
}

.appAlertHolder {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 10px;
  
}

.appAlert {
  max-width: calc(100% - 20px);
  width: 1000px;
}